import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../../styles/styleUtils'

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  color: white;
  top: 4rem;
  left: 2rem;
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
    top: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;
  text-align: left;
  background-color: #282b2e;
  color: white;
  padding: 2rem;
  width: 30%;
  max-height: 21rem;
  ${media.md`
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  height: 11rem;
  overflow: hidden;
  text-align: center;
  padding-bottom: 5%;
  ${media.md`
  `}
  ${media.sm`
    height: 4rem;
  `}
`

const CajaElementoCarta = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 1.25rem 1.5rem;
  ${media.md`
  `};
`
const TextoPlato = styled.p`
  font-weight: bold;
  ${media.md`
  `};
`
export const Pescados: React.FC = () => {
  const ImageQueryPescados = useStaticQuery(graphql`
    query ImageQueryPescados {
      QuienesSomos: file(
        relativePath: { eq: "imagenes/rodaballo-y-rapito.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const menu = [
    { textoPlato: 'Almejas a la marinera', price: '17,5€' },
    { textoPlato: 'Merluza rellena', price: '14,2€' },
    { textoPlato: 'Cogote de merluza al horno', price: '14,2€' },
    { textoPlato: 'Rodaballo a la Donostiarra', price: '19,3€' },
    { textoPlato: 'Ajoarriero con Caracoles', price: '13,5€' },
    { textoPlato: 'Rapito con salsa de setas', price: '16,9€' },
    { textoPlato: 'Kokotxas de bacalao', price: '15,9€' },
    { textoPlato: 'Pimientos Rellenos de Marisco', price: '13,5€' },
  ]

  return (
    <>
      <WrapperImageCabeceraDerecha>
        <TituloSeccion>Pescados</TituloSeccion>
        <ImagenQuienesSomos
          alt={'Plato de espárragos rellenos'}
          fluid={ImageQueryPescados.QuienesSomos.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="20% 20%"
        ></ImagenQuienesSomos>
      </WrapperImageCabeceraDerecha>
      {menu.map((item, index) => (
        <CajaElementoCarta key={index}>
          <TextoPlato>{item.textoPlato}</TextoPlato>
          <TextoPlato>{item.price}</TextoPlato>
        </CajaElementoCarta>
      ))}
    </>
  )
}
