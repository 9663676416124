import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../../styles/styleUtils'

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  color: white;
  top: 4rem;
  left: 2rem;
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1rem;
    right: 1rem;
    top: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;
  text-align: left;
  background-color: #282b2e;
  color: white;
  padding: 2rem;
  width: 30%;
  max-height: 21rem;
  ${media.md`
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  height: 11rem;
  overflow: hidden;
  text-align: center;
  padding-bottom: 5%;
  ${media.md`
  `}
  ${media.sm`
    height: 4rem;
  `}
`

const CajaElementoCarta = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 1.25rem 1.5rem;
  ${media.md`
  `};
`

const TextoPlato = styled.p`
  font-weight: bold;
  ${media.md`
  `};
`

export const ParaComenzar: React.FC = () => {
  const ImageQueryParaComenzar = useStaticQuery(graphql`
    query ImageQueryParaComenzar {
      QuienesSomos: file(
        relativePath: {
          eq: "imagenes/la-carta/esparragos-rellenos-y-ensalda-de-gulas.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const menu = [
    { textoPlato: 'Jamón Ibérico de Bellota', price: '19,6€' },
    {
      textoPlato:
        'Ensalada templada de gulas y frutos secos con vinagreta de Módena',
      price: '10,8€',
    },
    { textoPlato: 'Endivias con Marisco', price: '10,8€' },
    { textoPlato: 'Espárragos rellenos (Especialidad)', price: '14,4€' },
    { textoPlato: 'Gambas a la Plancha', price: '17,5€' },
    { textoPlato: 'Croquetas de jamón ibérico', price: '9,8€' },
    { textoPlato: 'Almejas Rellenas', price: '13,5€' },
    { textoPlato: 'Revuelto de hongos con foie', price: '16,3€' },
    { textoPlato: 'Sopa de Pescado', price: '8,5€' },
    {
      textoPlato: 'Pasta fettuccine con Langostinos a la Crema de Hongos',
      price: '13,5€',
    },

    { textoPlato: 'Alcachofas con jamón (Primavera otoño)', price: '9€' },
    { textoPlato: 'Alcachofas con almejas (Primavera otoño)', price: '16€' },
    {
      textoPlato:
        'Espárragos frescos con huevo escalfado (temporada primavera)',
      price: '9€',
    },
    { textoPlato: 'Pan', price: '1€' },
  ]

  return (
    <>
      <WrapperImageCabeceraDerecha id="para-comenzar">
        <TituloSeccion>Entrantes</TituloSeccion>
        <ImagenQuienesSomos
          alt={'Plato de espárragos rellenos'}
          fluid={ImageQueryParaComenzar.QuienesSomos.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="20% 20%"
        ></ImagenQuienesSomos>
      </WrapperImageCabeceraDerecha>
      {menu.map((item, index) => (
        <CajaElementoCarta key={index}>
          <TextoPlato>{item.textoPlato}</TextoPlato>
          <TextoPlato>{item.price}</TextoPlato>
        </CajaElementoCarta>
      ))}
    </>
  )
}
