import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../../styles/styleUtils'

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  color: white;
  top: 4rem;
  left: 2rem;
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
    top: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;
  text-align: left;
  background-color: #282b2e;
  color: white;
  padding: 2rem;
  width: 30%;
  max-height: 21rem;
  ${media.md`
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  height: 11rem;
  overflow: hidden;
  text-align: center;
  padding-bottom: 5%;
  ${media.md`
  `}
  ${media.sm`
    height: 4rem;
  `}
`

const CajaElementoCarta = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 1.25rem 1.5rem;
  ${media.md`
  `};
`

const TipoVino = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  ${media.md`
  `};
`
const TextoPlato = styled.p`
  font-weight: bold;
  ${media.md`
  `};
`

export const Bodega: React.FC = () => {
  const ImageQueryBodega = useStaticQuery(graphql`
    query ImageQueryBodega {
      QuienesSomos: file(relativePath: { eq: "imagenes/bodega-alargada.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const menu = [
    { tipoVino: 'TINTOS D.O. NAVARRA' },
    { textoPlato: 'Vino Lerín', price: '9€' },
    { textoPlato: 'Sardasol Crianza', price: '14€' },
    { textoPlato: 'Viña Sardasol Reserva', price: '16€' },
    { textoPlato: 'Sardasol monovarietal garnacha', price: '14,5€' },
    { textoPlato: 'Alconde Graciano', price: '18,5€' },
    { textoPlato: 'Alconde óptimo', price: '16€' },
    { tipoVino: 'TINTOS D.O. RIOJA' },
    { textoPlato: 'Marqués de Riscal Reserva', price: '25€' },
    { textoPlato: 'Marqués de Cáceres Crianza', price: '18,5€' },
    { textoPlato: 'Marqués de Cáceres Crianza 3/8', price: '11,5€' },
    { textoPlato: 'Marqués de Vitoria crianza', price: '16€' },
    { textoPlato: 'Faustino VII', price: '15€' },
    { textoPlato: 'El Coto Crianza', price: '15€' },
    { textoPlato: 'El Coto Crianza 3/8', price: '10,5€' },
    { textoPlato: 'Cantos de Valpiedra', price: '17€' },
    { textoPlato: 'Luis Cañas crianza', price: '19€' },
    { textoPlato: 'Luis Cañas crianza 1/2', price: '15€' },
    { textoPlato: 'Amaren crianza', price: '19€' },
    { tipoVino: 'TINTOS D.O. RIBERA DEL DUERO' },
    { textoPlato: 'Prado rey crianza Ribera del Duero', price: '22€' },
    { tipoVino: 'ROSADOS' },
    { textoPlato: 'Sardasol rosado', price: '13€' },
    { textoPlato: 'Rosado fresco Alconde (Aguja)', price: '12€' },
    { tipoVino: 'BLANCOS' },
    { textoPlato: 'Sardasol chardonay', price: '13€' },
    { textoPlato: 'Sardasol suavignon blanc', price: '13€' },
    { textoPlato: 'Verdejo Frizzy (frizante)', price: '13€' },
    { textoPlato: 'Blanco pescador (aguja)', price: '14€' },
    { textoPlato: 'Mantel blanco rueda', price: '13€' },
    { textoPlato: 'Sardasol blanco óptimo', price: '14€' },
    { textoPlato: 'Tilenus Godello D.O Bierzo', price: '14,5€' },
    { textoPlato: 'Alconde Moreno Cabezón garnacha', price: '28€' },
    { tipoVino: 'CAVAS' },
    { textoPlato: 'Anna de Codorniú brut reserva', price: '17,5€' },
    { tipoVino: 'SIDRAS' },
    { textoPlato: 'Sidra seca', price: '8€' },
  ]

  return (
    <>
      <WrapperImageCabeceraDerecha>
        <TituloSeccion>Bodega</TituloSeccion>
        <ImagenQuienesSomos
          alt={'Plato de espárragos rellenos'}
          fluid={ImageQueryBodega.QuienesSomos.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="20% 20%"
        ></ImagenQuienesSomos>
      </WrapperImageCabeceraDerecha>
      {menu.map((item, index) => (
        <CajaElementoCarta key={index}>
          {item.tipoVino ? (
            <TipoVino>{item.tipoVino}</TipoVino>
          ) : (
            <>
              <TextoPlato>{item.textoPlato}</TextoPlato>
              <TextoPlato>{item.price}</TextoPlato>
            </>
          )}
        </CajaElementoCarta>
      ))}
    </>
  )
}
