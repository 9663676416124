import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { ThemeProvider } from 'styled-components'
import { BarraNavegacion } from '../components/BarraNavegacion'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'
import { ParaComenzar } from '../components/la-carta/ParaComenzar'
import { Carnes } from '../components/la-carta/Carnes'
import { Pescados } from '../components/la-carta/Pescados'
import { Postres } from '../components/la-carta/Postres'
import { Bodega } from '../components/la-carta/Bodega'
import * as Scroll from 'react-scroll'
import { animateScroll as scroll } from 'react-scroll'
const Element = Scroll.Element
const scroller = Scroll.scroller
interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`

export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;

  width: 33%;
  z-index: 2;
  max-height: 21rem;
  ${media.md`
  display:none;
  `}
`

const CajaDerecha = styled.div`
  width: 66%;
  height: 100%;
  flex-direction: column;
  ${media.md`
    width: 100%;
  `};
`
const ContenedorLeft = styled.div`
  position: fixed;
  background-color: #282b2e;
  color: white;
  text-align: left;
  padding: 2rem;
  width: 31.6%;
  height: 11rem;
  z-index: 2;
  max-height: 22rem;
  p {
    padding: 0.2rem;
    :hover {
      font-weight: bold;
    }
    cursor: pointer;
  }
  ${media.md`
    height: 24rem;
  `};
`

const scrollCarta = (containerId: string) => {
  scroller.scrollTo(containerId, {
    duration: 1500,
    delay: 100,
    smooth: true,
    offset: -146,
  })
}

const scrollParaComenzar = () => {
  return scrollCarta('para-comenzar')
}

const scrollCarnes = () => {
  return scrollCarta('carnes')
}

const scrollPescados = () => {
  return scrollCarta('pescados')
}

const scrollPostres = () => {
  return scrollCarta('postres')
}

const scrollBodega = () => {
  return scrollCarta('bodega')
}

export default ({ data, location }: IndexPageProps) => {
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <CabeceraLaCarta id="caja-derecha">
            <ColumnaCabeceraCarta>
              <ContenedorLeft>
                <p onClick={() => scrollParaComenzar()}>Entrantes</p>
                <p onClick={() => scrollPescados()}>Pescados</p>
                <p onClick={() => scrollCarnes()}>Carnes</p>
                <p onClick={() => scrollPostres()}>Postres</p>
                <p onClick={() => scrollBodega()}>Bodega</p>
              </ContenedorLeft>
            </ColumnaCabeceraCarta>
            <CajaDerecha>
              <Element name="para-comenzar">
                <ParaComenzar />
              </Element>
              <div id="pescados">
                <Element name="pescados">
                  <Pescados key="pescados" />
                </Element>
              </div>
              <div id="carnes">
                <Element name="carnes">
                  <Carnes key="carnes" />
                </Element>
              </div>
              <div id="postres">
                <Element name="postres">
                  <Postres key="postres" />
                </Element>
              </div>
              <div id="bodega">
                <Element name="bodega">
                  <Bodega key="bodega" />
                </Element>
              </div>
            </CajaDerecha>
          </CabeceraLaCarta>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
